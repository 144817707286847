<template>
  <div>
    <v-dialog v-model="tourModal" persistent max-width="600px">
      <v-card color="#ece7e1">
        <div style="position: absolute; top: 3px; right: 3px">
          <v-btn icon color="black" to="/contact">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="text-center">
          <div class="text-center" style="width: 100%">
            <span
              class="black--text subtitles-big d-block founders-grotesk-light animation line-height-title-intro text-center d-block"
              >Reservations</span
            >
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <!-- <v-col cols="12" class="pb-0">
                  <span>Potential homeowner</span>
                </v-col> -->
                <template v-if="step === 1">
                  <v-col cols="12">
                    <v-date-picker
                      v-model="date"
                      @input="menu2 = false"
                      @change="changeAnimation()"
                      color="black"
                      :min="now"
                      width="100%"
                      header-color="rgb(236, 231, 225)"
                    ></v-date-picker>
                    <!-- <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          label="Preferred date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          color="black"
                          :disabled="sending"
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        @input="menu2 = false"
                        color="black"
                        :min="now"
                      ></v-date-picker>
                    </v-menu> -->

                    <div class="text-center">
                      <v-skeleton-loader
                        v-bind="attrs"
                        type="text"
                        v-if="isLoaded"
                        width="50%"
                        style="margin: auto; margin-bottom: 0 !important"
                      >
                      </v-skeleton-loader>
                      <span v-else
                        >TIME ZONE:
                        <span class="underline-custom"
                          >PACIFIC TIME (GMT-08:00)</span
                        ></span
                      >
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <!-- <v-select
                      :items="items"
                      label="Time"
                      color="black"
                      v-model="hour"
                      :rules="requiredRules"
                    ></v-select> -->

                    <v-row no-gutters>
                      <v-col
                        cols="6"
                        sm="4"
                        class="hour-column"
                        v-for="(item, index) in items"
                        v-bind:key="index"
                      >
                        <v-skeleton-loader
                          v-bind="attrs"
                          type="image"
                          v-if="isLoaded"
                          height="50"
                          v-bind:class="{
                            'd-none d-sm-block': index === items.length - 1,
                          }"
                        >
                        </v-skeleton-loader>
                        <v-btn
                          @click="hour = item"
                          v-bind:ripple="false"
                          v-bind:class="{
                            'btn-selected': hour === item,
                            'd-none d-sm-block': index === items.length - 1,
                          }"
                          elevation="0"
                          tile
                          v-else
                          ><span> {{ item }} </span></v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
                <template v-if="step === 2">
                  <v-col cols="12">
                    <div style="margin-bottom: 24px">
                      <span
                        class="uppercase founders-grotesk-bold"
                        style="color: black !important;"
                        >Appointment</span
                      >
                    </div>
                    <div
                      v-html="formatDate(date)"
                      style="line-height: normal; margin-bottom: 24px"
                    ></div>
                    <div>
                      <span
                        class="uppercase founders-grotesk-bold"
                        style="color: black !important"
                        >your information</span
                      >
                    </div>
                  </v-col>

                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="firstName"
                      label="First name*"
                      required
                      color="black"
                      :disabled="sending"
                      :rules="requiredRules"
                      class="mt-0 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="lastName"
                      label="Last name*"
                      required
                      color="black"
                      :disabled="sending"
                      :rules="requiredRules"
                      class="mt-0 pt-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="email"
                      label="Email*"
                      required
                      color="black"
                      :disabled="sending"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      v-model="phoneNumber"
                      label="Phone number*"
                      type="number"
                      required
                      color="black"
                      :disabled="sending"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </template>
                <!-- <v-col cols="12">
                    <v-text-field
                        v-model="company"
                        label="Company*"
                        required
                        color="black"
                        :disabled="sending"
                        :rules="requiredRules"
                    ></v-text-field>
                </v-col> -->

                <!-- <v-col cols="12" class="pb-0">
                  <span>Point of contact</span>
                </v-col> -->

                <!-- <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="firstNamePointOfContact"
                    label="First name*"
                    required
                    color="black"
                    :disabled="sending"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="lastNamePointOfContact"
                    label="Last name*"
                    required
                    color="black"
                    :disabled="sending"
                    :rules="requiredRules"
                  ></v-text-field>
                </v-col> -->

                <!-- <v-col cols="12">
                  <p>We are currently open by reservation to financially qualified guests. Unless it is apparent, we may ask for your proof of funds prior to your reservation confirmation.</p>
                </v-col> -->

                <!-- <v-col cols="12">
                  <v-textarea
                    rows="1"
                    label="Note"
                    color="black"
                    v-model="note"
                    auto-grow
                  ></v-textarea>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    label="Upload a Document"
                    color="black"
                    v-model="document"
                  ></v-file-input>
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="py-4 pt-0 card-actions-tour">
          <v-spacer></v-spacer>
          <div class="text-center">
            <!-- <template v-if="step === 1">
              <v-btn
                color="black"
                elevation="0"
                tile
                width="219px"
                @click="step = 2"
                :disabled="hour !== '' && date !== '' ? false : true"
              >
                <span
                  class="white--text continuos-texts"
                  style="text-transform: none"
                  >Next Step</span
                >
              </v-btn>
            </template> -->
            <template v-if="step === 2">
              <!-- <v-btn
                color="black"
                elevation="0"
                tile
                width="219px"
                @click="date = '', hour = ''"
              >
                <span
                  class="white--text continuos-texts"
                  style="text-transform: none"
                  >regresar</span
                >
              </v-btn> -->
              <v-btn
                color="black"
                elevation="0"
                tile
                width="219px"
                @click="sendRequest()"
                :disabled="sending"
              >
                <span
                  class="white--text continuos-texts"
                  style="text-transform: none"
                  >Request</span
                >
              </v-btn>

              <div
                class="opac-item mt-3 text-center"
                v-bind:class="{ on: sending }"
              >
                <span class="continuos-texts">
                  Thank you. Your info has been sent.
                </span>
              </div>
            </template>
          </div>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import axios from "axios";

export default {
  metaInfo: {
    title: "Book a Tour | Marisol Malibu",
    // titleTemplate: 'Marisol Malibu',
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      {
        vmid: "og:description",
        property: "og:description",
        name: "description",
        content: "Wow",
      },
    ],
  },
  name: "Tour",
  data() {
    return {
      attrs: {
        class: "mb-1",
      },
      token: "23c18a9579d81592ede1f10e70819a",
      tourModal: true,
      date: "",
      menu2: false,
      sending: false,
      firstName: "",
      lastName: "",
      // company: '',
      firstNamePointOfContact: "",
      lastNamePointOfContact: "",
      email: "",
      phoneNumber: "",
      note: "",
      document: [],
      requiredRules: [(v) => !!v || "* Required"],
      emailRules: [
        (v) => !!v || "* Required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      valid: true,
      documentToSend: [],
      routeOfDocument: [],
      referenceID: "",
      items: [
        "9:00am",
        "10:00am",
        "11:00am",
        "12:00pm",
        "1:00pm",
        "2:00pm",
        "3:00pm",
        "4:00pm",
        "5:00pm",
      ],
      hour: "",
      dateMinimum: "2021-11-15",
      step: 1,
      isLoaded: false,
    };
  },
  computed: {
    data: function () {
      return {
        preferredDate: this.date,
        firstName: this.firstName,
        lastName: this.lastName,
        // company: this.company,
        email: this.email,
        phoneNumber: this.phoneNumber,
        note: this.note,
        // document: this.documentToSend[0],
        referenceId: this.referenceID,
        suggestedHour: this.hour,
        status: "Pending",
      };
    },
    now: function () {
      var today = new Date();
      var monthExtra = "";
      var dayExtra = "";
      if (today.getMonth() < 9) {
        monthExtra = "0";
      }

      if (today.getDate() < 9) {
        dayExtra = "0";
      }

      var date =
        today.getFullYear() +
        "-" +
        monthExtra +
        (today.getMonth() + 1) +
        "-" +
        dayExtra +
        (today.getDate() + 2);
      return date;
    },
    dateAndHour: function () {
      return `${this.date}|${this.hour}`;
    },
  },
  methods: {
    changeAnimation() {
      this.isLoaded = true;
      setTimeout(() => {
        this.isLoaded = false;
      }, 300);
    },
    formatDate: function (date) {
      let formatDate = new Date(date);
      let year = formatDate.getFullYear();
      let day = formatDate.getDate() + 1;
      let month = formatDate.getMonth();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      let newFormatDate = months[month] + " " + day + " " + year;
      let newDate = new Date(newFormatDate);
      let newDateYear = newDate.getFullYear();
      let newDateMonth = newDate.getMonth();
      let newDateMonthInLetters = months[newDateMonth];
      const options = { weekday: "long" };
      let newDateDayInLetters = newDate.toLocaleDateString("en-us", options);
      let newDateDayInNumbers = newDate.getDate();
      let nthNumber = (number) => {
        if (number > 3 && number < 21) return "th";

        switch (number % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };
      let infoDate = `
        <p class="mb-0 zero-title" style="color:black !important">Zero 2 Tour with Marisol Malibu</p>
        <p class="format-date" style="color:black !important">${newDateDayInLetters}, ${newDateMonthInLetters} ${newDateDayInNumbers}${nthNumber(
        newDateDayInNumbers
      )}, ${newDateYear} at ${this.hour}</p>
      `;

      console.log(newDateDayInLetters);

      return infoDate;
    },
    sendRequest: function () {
      if (this.$refs.form.validate()) {
        var aux = this;
        this.sending = true;

        fetch("https://content.marisolmalibu.com/api/collections/save/Tours", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            data: this.data,
          }),
        })
          .then((res) => res.json())
          .then(() => {
            aux.sendClientMail();
          })
          .then(() => {
            aux.sendAdminMail();
          })
          .then(() => {
            aux.closeModal();
          });

        // this.sendClientMail();
        // this.sendAdminMail();
        // this.closeModal();

        // .then((res) => this.referenceID = res._id)
        // .then((res) => this.sendForm(res._id))
        // .catch((error) => console.log(error.message));
      }
    },
    sendClientMail: function () {
      const form = new FormData();
      form.append("to", this.email);
      form.append("template", "marisolmalibu-client-booked");
      form.append("h:X-Mailgun-Variables", '{"test": "test"}');

      fetch("https://api.mailgun.net/v3/mg.marisolmalibu.com/messages", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("api:fc4d204389de92fe77e32e2b78bc227a-73f745ed-ebdc55df"),
        },
        body: form,
      }).then((res) => {
        console.log(res);
      });
    },
    sendAdminMail: function () {
      const form = new FormData();
      form.append("to", "sales@marisolmalibu.com");
      form.append("template", "marisolmalibu-client-booked-admin-notification");
      form.append("h:X-Mailgun-Variables", '{"test": "test"}');

      fetch("https://api.mailgun.net/v3/mg.marisolmalibu.com/messages", {
        method: "POST",
        headers: {
          Authorization:
            "Basic " +
            btoa("api:fc4d204389de92fe77e32e2b78bc227a-73f745ed-ebdc55df"),
        },
        body: form,
      });
    },
    sendForm: function (_id) {
      fetch(
        "https://content.marisolmalibu.com/api/forms/submit/Tours?token=" +
          this.token,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            form: this.data,
          }),
        }
      )
        .then((entry) => entry.json())
        .then(this.sendMail(_id))
        .catch((error) => console.log(error.message));
    },
    sendMail: function (_id) {
      console.log("id" + _id);
      fetch("https://www.utils.actstudio.xyz/dimal.php?referencia=" + _id)
        .then((res) => console.log(res))
        .then(this.closeModal());
    },
    closeModal: function () {
      // this.$emit('update');

      setTimeout(() => {
        this.tourModal = false;
        this.$router.push("/contact");
      }, 1000);
    },
    preSelectedDate: function () {
      var today = new Date();
      var monthExtra = "";
      var dayExtra = "";
      if (today.getMonth() < 9) {
        monthExtra = "0";
      }

      if (today.getDate() < 9) {
        dayExtra = "0";
      }

      var date =
        today.getFullYear() +
        "-" +
        monthExtra +
        (today.getMonth() + 1) +
        "-" +
        dayExtra +
        (today.getDate() + 2);

      this.date = date;
    },
  },
  watch: {
    dateAndHour(val) {
      let array = val.split("|");
      if (array[0] !== "" && array[1] !== "") {
        setTimeout(() => {
          this.step = 2;
        }, 1000);
      } else {
        this.step = 1;
      }
    },
  },
  mounted: function () {
    this.$gtag.pageview({ page_path: "/contact/book-a-tour" });

    setTimeout(() => {
      this.preSelectedDate();
    });
  },
};
</script>

<style lang="scss" scoped>
.hour-column {
  margin-bottom: 12px;
  padding: 0 5px !important;
  .v-btn {
    width: 100%;
    border: 1px solid black !important;
    background: transparent !important;

    &.btn-selected {
      background: black !important;
      border-color: white !important;
      span {
        color: white !important;
      }
    }
  }
}
.card-actions-tour {
  margin-top: 16px;
}
</style>